var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.doc && _vm.showCompletedFilingSlide
        ? _c("completed-filing", {
            attrs: { "filing-type": _vm.filingType },
            on: {
              "next-slide": function ($event) {
                return _vm.$emit("next-slide")
              },
            },
          })
        : _c(
            "div",
            { attrs: { id: "document-upload-slide-component" } },
            [
              _vm.loaded
                ? [
                    _c("slide-heading", {
                      attrs: { heading: _vm.slideHeading },
                    }),
                    _c("slide-image-and-description", {
                      attrs: { "override-description": _vm.uploadDescription },
                    }),
                    _c("ct-file-drop", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.documentUploadEnabled,
                          expression: "documentUploadEnabled",
                        },
                      ],
                      ref: "document-upload-file-drop",
                      staticClass: "my-5",
                      attrs: {
                        "seconds-estimate": 12,
                        "acceptable-file-types": ["application/pdf"],
                        "max-size-kb": _vm.finalizeLegalDocument ? 10000 : 1024,
                        "file-type-error-text": "Files must be PDF documents.",
                      },
                      on: { dropped: _vm.uploadDocument },
                    }),
                    _c("div", { staticClass: "img-viewer-container" }, [
                      _vm.doc
                        ? _c(
                            "div",
                            { staticClass: "img-viewer" },
                            [
                              _c(
                                "b-container",
                                { staticClass: "md-2 sm-4 img-container" },
                                [
                                  _vm.pageLoaded && _vm.imageURL
                                    ? _c("b-img", {
                                        staticClass: "client-document-page",
                                        attrs: {
                                          src: _vm.imageURL,
                                          "blank-color": "#bbb",
                                          "aria-label":
                                            "document load error image",
                                        },
                                      })
                                    : _vm.pageLoaded && !_vm.imageURL
                                    ? _c(
                                        "span",
                                        [
                                          _c("fa-icon", {
                                            attrs: {
                                              icon: "exclamation-triangle",
                                            },
                                          }),
                                          _c("p", [
                                            _vm._v(
                                              "\n                Unable to Load Document\n              "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c("ct-centered-spinner"),
                                ],
                                1
                              ),
                              _vm.doc?.pages?.length > 0
                                ? _c("b-pagination", {
                                    staticClass: "custom-pagination",
                                    attrs: {
                                      align: "center",
                                      "total-rows": _vm.doc?.pages?.length,
                                      "per-page": 1,
                                    },
                                    on: { change: _vm.pageChanged },
                                    model: {
                                      value: _vm.pageNumber,
                                      callback: function ($$v) {
                                        _vm.pageNumber = $$v
                                      },
                                      expression: "pageNumber",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.showInitialResolutionError
                        ? _c(
                            "div",
                            { staticClass: "img-viewer" },
                            [
                              _c(
                                "b-container",
                                { staticClass: "md-2 sm-4 img-container" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("fa-icon", {
                                        attrs: { icon: "exclamation-triangle" },
                                      }),
                                      _c("p", { staticClass: "mt-2" }, [
                                        _vm._v(
                                          "\n                Oops!\n              "
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "\n                It's not you, it's us.\n              "
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "\n                Call or email our support staff and we'll get this taken care of.\n              "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                : _c("ct-centered-spinner", [
                    _vm._v("\n      Loading...\n    "),
                  ]),
              _c(
                "div",
                { staticClass: "buttons mt-3 mb-0 d-flex flex-column" },
                [
                  _vm.doc && !_vm.filesUploading
                    ? [
                        ["xs", "sm"].includes(_vm.$mq)
                          ? _c(
                              "div",
                              { staticClass: "full-screen-buttons" },
                              [
                                _vm.doc.source !== "stageline"
                                  ? [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "file-button",
                                          attrs: {
                                            variant: "default",
                                            "aria-label": "print button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.printPdf()
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              type: "printer",
                                              "stroke-width": 2,
                                              height: "24",
                                              width: "24",
                                            },
                                          }),
                                          _vm._v(
                                            "\n              Print\n            "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "file-button",
                                          attrs: {
                                            variant: "default",
                                            "aria-label": "download button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadPdf()
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              type: "download-cloud",
                                              "stroke-width": 2,
                                              height: "24",
                                              width: "24",
                                            },
                                          }),
                                          _vm._v(
                                            "\n              Download\n            "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                [
                                  _vm.showUpdate
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "file-button",
                                          attrs: {
                                            variant: "default",
                                            "aria-label": "update button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs[
                                                "document-upload-file-drop"
                                              ].openFileDialogBox()
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              type: "refresh-ccw",
                                              "stroke-width": 2,
                                              height: "24",
                                              width: "24",
                                            },
                                          }),
                                          _vm._v(
                                            "\n              Update\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showRemove
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "file-button",
                                          attrs: {
                                            variant: "default",
                                            "aria-label": "remove button",
                                          },
                                          on: {
                                            click:
                                              _vm.showRemoveDocumentConfirmationModal,
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              type: "minus-circle",
                                              "stroke-width": 2,
                                              height: "24",
                                              width: "24",
                                            },
                                          }),
                                          _vm._v(
                                            "\n              Remove\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "slide-viewer-button-row mt-3 mb-0" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "default",
                            "aria-label": "back button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("previous-slide")
                            },
                          },
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "fa-xs",
                            attrs: { icon: "chevron-left" },
                          }),
                          _vm._v("\n          Back\n        "),
                        ],
                        1
                      ),
                      !["xs", "sm"].includes(_vm.$mq)
                        ? [
                            _vm.doc && !_vm.filesUploading
                              ? [
                                  _vm.doc.source !== "stageline"
                                    ? [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "print button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.printPdf()
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                type: "printer",
                                                "stroke-width": 2,
                                                height: "24",
                                                width: "24",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                Print\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "download button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadPdf()
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                type: "download-cloud",
                                                "stroke-width": 2,
                                                height: "24",
                                                width: "24",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                Download\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  [
                                    _vm.showRemove
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "remove button",
                                            },
                                            on: {
                                              click:
                                                _vm.showRemoveDocumentConfirmationModal,
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                type: "minus-circle",
                                                "stroke-width": 2,
                                                height: "24",
                                                width: "24",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                Remove\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.showUpdate
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "file-button",
                                            attrs: {
                                              variant: "default",
                                              "aria-label": "update button",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$refs[
                                                  "document-upload-file-drop"
                                                ].openFileDialogBox()
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                type: "refresh-ccw",
                                                "stroke-width": 2,
                                                height: "24",
                                                width: "24",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                Update\n              "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "next-button",
                          attrs: {
                            variant: "primary",
                            "aria-label": _vm.nextButtonLabel + " button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleNextSlide()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.nextButtonLabel) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                2
              ),
              _c(
                "ct-info-modal",
                {
                  attrs: { "modal-ref": "document-remove-modal" },
                  on: {
                    cancel: _vm.hideRemoveDocumentConfirmationModal,
                    ok: _vm.removeDocument,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-title",
                      fn: function () {
                        return [_vm._v("\n        Are you sure?\n      ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "ok",
                      fn: function () {
                        return [_vm._v("\n        Remove Document\n      ")]
                      },
                      proxy: true,
                    },
                    {
                      key: "cancel",
                      fn: function () {
                        return [_vm._v("\n        Cancel\n      ")]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  [
                    _vm._v(
                      "\n        Your document will no longer be accessible and will need to be re-uploaded.\n        Continue to remove document?\n      "
                    ),
                  ],
                ],
                2
              ),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }