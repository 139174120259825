<template>
  <div>
    <completed-filing
      v-if="doc && showCompletedFilingSlide"
      :filing-type="filingType"
      @next-slide="$emit('next-slide')"
    />

    <div v-else id="document-upload-slide-component">
      <template v-if="loaded">
        <slide-heading :heading="slideHeading" />
        <slide-image-and-description :override-description="uploadDescription" />

        <ct-file-drop
          v-show="documentUploadEnabled"
          ref="document-upload-file-drop"
          class="my-5"
          :seconds-estimate="12"
          :acceptable-file-types="['application/pdf']"
          :max-size-kb="finalizeLegalDocument ? 10000 : 1024"
          file-type-error-text="Files must be PDF documents."
          @dropped="uploadDocument"
        />

        <div class="img-viewer-container">
          <div v-if="doc" class="img-viewer">
            <b-container class="md-2 sm-4 img-container">
              <b-img
                v-if="pageLoaded && imageURL"
                :src="imageURL"
                :blank-color="'#bbb'"
                aria-label="document load error image"
                class="client-document-page"
              />
              <span v-else-if="pageLoaded && !imageURL">
                <fa-icon icon="exclamation-triangle" />
                <p>
                  Unable to Load Document
                </p>
              </span>
              <ct-centered-spinner v-else />
            </b-container>

            <b-pagination
              v-if="doc?.pages?.length > 0"
              v-model="pageNumber"
              align="center"
              class="custom-pagination"
              :total-rows="doc?.pages?.length"
              :per-page="1"
              @change="pageChanged"
            />
          </div>

          <div v-else-if="showInitialResolutionError" class="img-viewer">
            <b-container class="md-2 sm-4 img-container">
              <div>
                <fa-icon icon="exclamation-triangle" />
                <p class="mt-2">
                  Oops!
                </p>
                <p>
                  It's not you, it's us.
                </p>
                <p>
                  Call or email our support staff and we'll get this taken care of.
                </p>
              </div>
            </b-container>
          </div>
        </div>
      </template>

      <ct-centered-spinner v-else>
        Loading...
      </ct-centered-spinner>

      <!-- Buttons ---------------------------------------------------------------------------------->
      <div class="buttons mt-3 mb-0 d-flex flex-column">
        <!-- Show these buttons if document uploaded -->
        <template v-if="doc && !filesUploading">
          <div v-if="['xs', 'sm'].includes($mq)" class="full-screen-buttons">
            <template v-if="doc.source !== 'stageline'">
              <b-button
                variant="default"
                class="file-button"
                aria-label="print button"
                @click="printPdf()"
              >
                <feather-icon type="printer" :stroke-width="2" height="24" width="24" />
                Print
              </b-button>
              <b-button
                variant="default"
                class="file-button"
                aria-label="download button"
                @click="downloadPdf()"
              >
                <feather-icon type="download-cloud" :stroke-width="2" height="24" width="24" />
                Download
              </b-button>
            </template>
            <template>
              <b-button
                v-if="showUpdate"
                variant="default"
                class="file-button"
                aria-label="update button"
                @click="$refs['document-upload-file-drop'].openFileDialogBox()"
              >
                <feather-icon type="refresh-ccw" :stroke-width="2" height="24" width="24" />
                Update
              </b-button>

              <b-button
                v-if="showRemove"
                variant="default"
                class="file-button"
                aria-label="remove button"
                @click="showRemoveDocumentConfirmationModal"
              >
                <feather-icon type="minus-circle" :stroke-width="2" height="24" width="24" />
                Remove
              </b-button>
            </template>
          </div>
        </template>

        <div class="slide-viewer-button-row mt-3 mb-0 ">
          <b-button
            variant="default"
            aria-label="back button"
            @click="$emit('previous-slide')"
          >
            <fa-icon icon="chevron-left" class="fa-xs" />
            Back
          </b-button>

          <!-- Show these buttons if document uploaded. Repeated from above section since order in
          mobile view is different. -->
          <template v-if="!['xs', 'sm'].includes($mq)">
            <template v-if="doc && !filesUploading">
              <template v-if="doc.source !== 'stageline'">
                <b-button
                  variant="default"
                  class="file-button"
                  aria-label="print button"
                  @click="printPdf()"
                >
                  <feather-icon type="printer" :stroke-width="2" height="24" width="24" />
                  Print
                </b-button>
                <b-button
                  variant="default"
                  class="file-button"
                  aria-label="download button"
                  @click="downloadPdf()"
                >
                  <feather-icon type="download-cloud" :stroke-width="2" height="24" width="24" />
                  Download
                </b-button>
              </template>

              <template>
                <b-button
                  v-if="showRemove"
                  variant="default"
                  class="file-button"
                  aria-label="remove button"
                  @click="showRemoveDocumentConfirmationModal"
                >
                  <feather-icon type="minus-circle" :stroke-width="2" height="24" width="24" />
                  Remove
                </b-button>
                <b-button
                  v-if="showUpdate"
                  variant="default"
                  class="file-button"
                  aria-label="update button"
                  @click="$refs['document-upload-file-drop'].openFileDialogBox()"
                >
                  <feather-icon type="refresh-ccw" :stroke-width="2" height="24" width="24" />
                  Update
                </b-button>
              </template>
            </template>
          </template>

          <b-button
            class="next-button"
            variant="primary"
            :aria-label="nextButtonLabel + ' button'"
            @click="handleNextSlide()"
          >
            {{ nextButtonLabel }}
          </b-button>
        </div>
      </div>

      <ct-info-modal
        modal-ref="document-remove-modal"
        @cancel="hideRemoveDocumentConfirmationModal"
        @ok="removeDocument"
      >
        <template #modal-title>
          Are you sure?
        </template>
        <template>
          Your document will no longer be accessible and will need to be re-uploaded.
          Continue to remove document?
        </template>
        <template #ok>
          Remove Document
        </template>
        <template #cancel>
          Cancel
        </template>
      </ct-info-modal>
    </div>
  </div>
</template>

<script>
import http from '@/http'
import { documentViewMixin } from '@/mixins/documentViewMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentUploadSlide',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    CtFileDrop:        () => import('@/components/shared/CtFileDrop'),
    SlideHeading:      () => import('@/components/StagelineV2/shared/SlideHeading'),
    FeatherIcon:       () => import('@/components/shared/FeatherIcon'),
    CtInfoModal:       () => import('@/components/shared/CtInfoModal'),
    CompletedFiling:   () => import('@/components/StagelineV2/shared/CompletedFiling.vue'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
  },
  mixins: [
    documentViewMixin,
    makeToastMixin,
  ],
  props: {
    slide: null,
  },
  data() {
    return {
      // NOTE: doc and other data vals come in from documentViewMixin
      loaded: false,
      filingType: 'form a company',
      showCompletedFilingSlide: false,
    }
  },
  computed: {
    ...mapGetters('documents', [
      'clientDocument',
    ]),
    ...mapGetters('fileDrop', [
      'filesUploading',
    ]),
    ...mapGetters('stageline', [
      'company',
      'finalizeLegalDocResourceTypes',
      'formationFiling',
      'jurisdiction',
    ]),
    formationDocumentSlideSubType () {
      return this.slide?.layout_sub_type === 'Formation Document'
    },
    slideHeading () {
      return this.doc && this.formationDocumentSlideSubType ? 'Review Document' : this.slide.heading
    },
    finalizeLegalDocument () {
      return this.finalizeLegalDocResourceTypes.includes(this.slide.layout_sub_type?.toLowerCase())
    },
    showUpdate () {
      return this.doc?.source === 'stageline' || this.finalizeLegalDocument
    },
    showRemove () {
      return this.doc?.source === 'stageline'
    },
    nextButtonLabel () {
      if (!this.doc) {
        return 'Skip'
      } else if (this.doc.source === 'stageline') {
        return 'Save'
      } else {
        return 'Continue'
      }
    },
    uploadEnabledForDocType () {
      return this.slide?.layout_sub_type !== 'Initial Resolution'
    },
    documentUploadEnabled () {
      return !this.doc && this.uploadEnabledForDocType
    },
    showInitialResolutionError () {
      return !this.doc && this.slide?.layout_sub_type === 'Initial Resolution'
    },
    uploadDescription () {
      let description = ''

      if (this.documentUploadEnabled && this.slide?.layout_sub_type === 'Formation Document') {
        description = `
      <p>
        Once you have your filed document back from the state you can store a copy in your online account for safe keeping.
      </p>
      <p>
        This document is proof of existence of your business entity and is often required for
        other applications, like opening a business bank account or applying for a business loan.
      </p>`
      } else {
        description = this.slide.description || ''
      }

      // Append the span if 'doc' is present
      if (this.doc) {
        description += `
      <span>
        We will always keep a digital copy stored safely in your account.
      </span>`
      }

      return description;
    },
  },
  async mounted() {
    await this.fetchAndLoadClientDocumentPage()
    this.loaded = true
  },
  methods: {
    ...mapActions('fileDrop', [
      'resetFileDrop',
      'setFilesUploaded',
      'setFilesUploading',
    ]),
    ...mapActions('documents', [
      'fetchDocumentByType',
      'deleteClientDocument',
      'markRead',
    ]),
    ...mapActions('stageline', [
      'goToPreviousSlide',
      'goToNextSlide',
      'updateSlideProgress',
    ]),
    async fetchAndLoadClientDocumentPage() {
      await this.fetchDocumentByType({
        company_id: this.company.id,
        type: this.slide.layout_sub_type,
      })
      if (this.clientDocument) {
        this.doc = this.clientDocument
        await this.loadPage()
        if (this.doc.status !== 'read') await this.markRead({ id: this.doc.id })
      }
    },
    showRemoveDocumentConfirmationModal() {
      this.$bvModal.show('document-remove-modal')
    },
    hideRemoveDocumentConfirmationModal() {
      this.$bvModal.hide('document-remove-modal')
    },
    async removeDocument() {
      this.loaded = false
      // cache the doc id in a var so we can unset the doc immediately for the user
      const docId = this.doc.id
      this.doc = null
      await this.deleteClientDocument({ id: docId })

      this.loaded = true
    },
    async uploadDocument(files) {
      if (!files.length) throw new Error('No files were provided.')
      this.setFilesUploaded(false)
      this.setFilesUploading(true)

      // Delete and overwrite current client document if local doc.id
      if (this.doc?.id) {
        const docId = this.doc.id
        this.doc = null
        await this.deleteClientDocument({ id: docId })
      }

      const formData = new FormData()
      files.forEach(file => formData.append('files[]', file))
      formData.append('source', 'stageline')
      formData.append('type', this.slide.layout_sub_type)
      formData.append('company_id', this.company.id)
      formData.append('jurisdiction_id', this.jurisdiction.id)

      const response = await http.post('client/client_documents/client_upload', formData)

      if (response.data?.response?.result) {
        // If document was successfully uploaded, fetch it and display.
        await this.fetchAndLoadClientDocumentPage()
        this.setFilesUploaded(true)
        this.successToast('Document Upload Successful', 'Your document was successfully uploaded.')
      } else {
        this.setFilesUploaded(false)
        this.errorToast('Error uploading client document', response.data.response.error)
      }
      this.setFilesUploading(false)
    },
    async downloadPdf() {
      this.updateSlideProgress('document_downloaded_at')
      await this.download()
    },
    async printPdf() {
      this.updateSlideProgress('document_printed_at')
      await this.print()
    },
    handleNextSlide() {
      this.formationDocumentSlideSubType && this.doc ?
        this.showCompletedFilingSlide= true :
        this.$emit('next-slide')
    },
  },
}
</script>

<style lang="scss" scoped>
#document-upload-slide-component {
  .buttons {
    .full-screen-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.25em;
      button {
        margin-top: 0.5em;
      }
    }
    .slide-viewer-button-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin-left: 0.9375em;
      }
    }
    button.file-button {
      border: 1px $ct-ui-primary solid !important;
    }
    ::v-deep .feather {
      margin-right: 0.375em;
    }
  }

  .img-viewer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .img-viewer {
      margin: 1em 0 1em 0;
      background: $ct-ui-background-4;
      border: 1px $ct-ui-border-color-light solid;
      border-radius: 10px;
      width: fit-content;
      .img-container {
        margin: 1em 0 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50vh;
        .client-document-page {
          height: 100%;
          margin-top: 0 !important;
          width: auto;
        }
      }
      ::v-deep .custom-pagination {
        position: relative !important;
        width: 100% !important;
        height: unset !important;
        padding: 0 1em 0 1em;
        .page-item.active .page-link {
          background-color: $ct-ui-primary !important;
        }
        .page-item:not(.active) .page-link {
          color: $ct-ui-primary !important;
        }
      }
    }
  }

  .center-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 3.75em;
  }

  .max-button {
    max-width: 300px;
  }

  @media only screen and (max-width: 767px) {
    .btn-container-mobile {
      padding: 0 !important;

      .btn-group-mobile {
        width: 100% !important;
        margin: 0 !important;
      }
    }

    .flex-row-with-gap {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      row-gap: 1.0em;
    }
  }
}
</style>
